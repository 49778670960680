import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table as _Table } from 'antd';
import { useHistory, useParams, useLocation } from "react-router-dom";
import _ from 'lodash'
import { arrayMoveImmutable } from 'array-move';
import { MenuOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Button from './Button';

const DragHandle = SortableHandle(() => (<MenuOutlined style={{ cursor: 'grab', color: '#999', }} />));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);



/*****************
    <Table
        columns={columnsArray}
        dataSource={userRolesQuery ? userRolesQuery.edges : null}
        title={() => <span>Header</span>}
        footer={() => 'Footer'}
        rowKey={record => record._id}
        expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
            rowExpandable: record => record.name !== 'Not Expandable',
        }}
    />
 */
export const Table = props => {
    const [dataSource, setDataSource] = useState(false);
    const [sortUpdated, set_sortUpdated] = useState(false);
    const [savingSort, set_savingSort] = useState(false);

    //   let allParams = useParams();
    let history = useHistory();
    let location = useLocation();


    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );
            // console.log('Sorted items: ', newData);
            setDataSource(newData);
            // if (props.onSortComplete)
            set_sortUpdated(true)
        }
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const saveSorting = async() => {
        set_savingSort(true)
        await props.saveSorting(dataSource)
        set_savingSort(false)
        set_sortUpdated(false)
    }

    useEffect(() => {
        // if (!props.dataSource) return;
        setDataSource(props?.dataSource?.map((o, i) => ({ ...o, index: o._id })))
    }, [props.dataSource])






    let _props = { ...props };
    
    let columns = _props.columns.slice();
    if (props.isSotrable){
        columns.unshift({
            title: 'Sort', dataIndex: 'sort', width: 30, className: 'drag-visible', align: "center",
            render: () => <span style={{ fontSize: "16px" }}><DragHandle /></span>,
        })
    }


    _props = Object.assign(_props, {
        bordered: (props.bordered === false) ? false : true,
        scroll: (props.scroll === false) ? false : props.scroll,
        size: props.size || "medium", // small, medium, default
        columns,
    })
    if (!props.rowKey) _props = Object.assign(_props, { rowKey: (data) => data._id });

    const onPaginationChange = (page, pageSize) => {
        if (props.updateHistory) {
            let loc = String(location.pathname);
            let searchVal = /\/page\/[0-9]+/i;
            if (loc.search(searchVal) > -1) {
                loc = loc.replace(searchVal, `/page/${page}`)
                history.push(loc)
            }
        }

        if (props?.pagination?.onChange) props.pagination.onChange(page, pageSize)
    }

    if (props.pagination !== false && (!props.pagination || !props.pagination.position)) {
        let pagination = { showSizeChanger:false, ...props.pagination }
        pagination = Object.assign(pagination, {position: ['none', 'bottomCenter']} )
        _props = Object.assign(_props, { pagination: { ...pagination, onChange: onPaginationChange} })
        // console.log("pagination: ", pagination);
    }

    if (!props.rowClassName && !props.disableStatusHighight) {
        _props = Object.assign(_props, {
            rowClassName:(record => {
                let classes = 'show-menu-on-hover '
                if (record.status == 'disabled') classes += 'disabled-table-row '
                return classes; //record.status == 'disabled' ? 'disabled-table-row' : "";
            })
        })
    }

    // if (props.isSotrable){
    //     console.log("dataSource: ", dataSource)
    //     console.log("_props.columns: ", _props.columns)
    // }

    
    return (<>
        <_Table {..._props}
            pagination={props.isSotrable ? false : props.pagination}
            dataSource={!dataSource ? false : dataSource}
            components={(!props.isSotrable || !dataSource) ? false : {
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }}
        />

        {(sortUpdated && props.isSotrable) && <div align="center"><Button loading={savingSort} onClick={() => saveSorting(dataSource)} color="orange">Save New Sorting</Button></div>}
    </>)
}

Table.propTypes = {
    isSotrable: PropTypes.bool,
    setDataSource: PropTypes.func,
    saveSorting: PropTypes.func,
    dataSource: PropTypes.array,
    bordered: PropTypes.bool,
    updateHistory: PropTypes.bool,
    scroll: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    loading: PropTypes.bool,
    showHeader: PropTypes.bool,
    rowClassName: PropTypes.func,
    disableStatusHighight: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    // pagination: PropTypes.object,
    rowKey: PropTypes.func,
    title: PropTypes.func,
    footer: PropTypes.func,
    pagination: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.object
            ]),
    // prop: PropTypes.type.isRequired
}
