import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { ListHeader } from 'Common/components/Typography';
import { Col, Row, Space } from 'antd';
import TicketsSearchBar from './Tickets_SearchBar';
import { Avatar, DataRow, DeleteButton, IconButton, Table } from 'Common/components';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import { defaultDateTimeFormat, defaultPageSize } from 'configs';
import { Link } from 'react-router-dom';
import { ROOT_PATH } from '../../constants';
import { verifyRole } from 'Common/scripts/Security';
import { connect } from "react-redux";
import { sleep } from 'Common/scripts/Functions';
import { __yellow } from 'Common/scripts/consoleHelper';

const GET_TICKET_PAGINATION = loader('src/Graphql/threads/threadsTicketsQuery.graphql');
const GET_TICKET_PAGINATION_SEARCH = loader('src/Graphql/threads/threadsTicketsQuerySearch.graphql');

class SubscriptionHandler extends Component {
    componentDidMount() {
        document.body.addEventListener('ticket-event', this.onHandel.bind(this));
    }

    componentWillUnmount() {
        document.body.removeEventListener('ticket-event', this.onHandel.bind(this));
    }

    onHandel(e) {
        this.props.handleEventChange(e)
    }

    render() {
        return null
    }
}


const TicketList = ({ title, filter, user, onEditClick, onDeleteClick, permanentFilter, listSize }) => {
    const [pagination, setPagination] = useState({ first: listSize || defaultPageSize, after: 0, filter: { ...permanentFilter }, others: { sort: { _id: -1 } } })
    const [ticketList, set_ticketList] = useState(null)
    const [queryResults, set_queryResults] = useState(null)

    console.log("queryResults: ", queryResults)

    const [threadsTicketsQuery, { called, loading, data }] = useLazyQuery(
        GET_TICKET_PAGINATION, { variables: { ...pagination, filter: JSON.stringify(pagination.filter), others: JSON.stringify(pagination.others) } }
    );
    const [threadsTicketsQuerySearch, { called: search_called, loading: search_loading, data: search_data }] = useLazyQuery(
        GET_TICKET_PAGINATION_SEARCH, { variables: { ...pagination, filter: JSON.stringify(pagination.filter), others: JSON.stringify(pagination.others) } }
    );

    useEffect(() => {
        // if (called || loading || data) return;
        let variables = {
            first: listSize || defaultPageSize, // Number(pagination.first),
            after: 0, // Number(pagination.after),
            filter: JSON.stringify({ ...filter, ...permanentFilter }),
            others: JSON.stringify(pagination.others)
        }

        if (JSON.stringify(filter) !== JSON.stringify(pagination.filter)){
            setPagination({ 
                first: listSize || defaultPageSize, after: 0, 
                filter: { ...filter, ...permanentFilter }, 
                others: pagination.others,
             })
        }

        threadsTicketsQuery({ variables }).then(r => {
            set_ticketList(r?.data?.threadsTicketsQuery?.edges);
            set_queryResults(r?.data?.threadsTicketsQuery);
        })
    }, [filter])


    const columns = () => [
        {
            title: 'Title', fixed: 'title', key: 'title', render: (__, rec) => {
                return (<>
                    <Space>
                        <Avatar src={rec.author.avatar} alt={rec.author.name} tooltip={`Created by ${rec.author.name}`} size={25} />
                        <Link to={`${ROOT_PATH}/ticket/${rec._id}`}>{rec.subject}</Link>
                    </Space>
                    {/* <div style={{ height: "10px" }} /> */}

                    {rec?.messages?.length>0 && <>
                        {rec?.messages.map((item, i)=>{
                            return <div key={i} style={{ border:"1px solid #EEE", padding:"0px 5px", margin:"5px", borderRadius:"5px" }}>
                                <DataRow col1={`by ${item.author.name}`} col2={item.body} />
                                {/* <div>by: {item.author.name}</div>
                                {item.body} */}
                            </div>
                        })}
                    </>}

                    <span className='hidden-menu'>
                        {(user._id == rec.author._id && onEditClick) && <IconButton className="item" icon="pen" onClick={() => onEditClick(rec)} />}
                        {(verifyRole('th.tik.del', {user}) && user._id == rec.author._id && onDeleteClick) && <DeleteButton className="item" onConfirm={() => onDeleteClick(rec?._id)} />}
                        {/* {(verifyRole('ticket.update', user) && onEditClick) && <IconButton className="item" icon="pen" onClick={() => onEditClick(rec)} />} */}
                        {/* {(verifyRole('th.tik.del', user) && onDeleteClick) && <DeleteButton className="item" onConfirm={() => onDeleteClick(rec?._id)} />} */}
                    </span>
                </>)
            }
        },
        // { title: 'Channel', width: 150, key: 'channel', dataIndex: 'status', render: (__, rec) => (rec?.channel?.title) },
        { title: 'Priority', width: 100, key: 'priority', dataIndex: 'priority', render: (text, rec) => {
            return <span className={`info_tag ${text == "high" ? "red" : ""}`}>{text}</span>
        }},
        { title: 'Status', width: 100, key: 'status', dataIndex: 'status', render:(text, rec) => {
            return <span className={`info_tag ${text == "closed" ? "gray" : ""}`}>{text}</span>
        } },
    ];

    const handleTableChange = async ({ first, after, current, filters }) => {
        // { new_pagination, filters, sorter }
        // console.log(__yellow("handleTableChange()"), { after, current, filters, others, pageSize, total })

        let _filters = filters || pagination.filter;
        let _first = Number(first || listSize || defaultPageSize || pagination.first);
        let _page = Number((current || 1) - 1);
        let _after = Number(_page * _first);

        let variables = {
            first: _first,
            after: _after,
            filter: JSON.stringify(_filters),
            others: JSON.stringify(pagination.others),
        }

        setPagination({
            first: _first,
            after: _after,
            filter: { ..._filters, ...permanentFilter },
            others: pagination.others,
        })

        if (_filters.keyword) {
            threadsTicketsQuerySearch({ variables }).then(r => {
                set_ticketList(r?.data?.threadsTicketsQuerySearch?.edges);
                set_queryResults(r?.data?.threadsTicketsQuerySearch);
            })
        } else {
            threadsTicketsQuery({ variables }).then(r => {
                set_ticketList(r?.data?.threadsTicketsQuery?.edges);
                set_queryResults(r?.data?.threadsTicketsQuery);
            })
        }



        /*
        console.log("handleTableChange()", { pagination: new_pagination, filters, sorter });

        let _filters = pagination.filter;
        if (filters) _filters = filters;

        setPagination({
            first: listSize || defaultPageSize, after: 0,
            filter: { ..._filters, ...permanentFilter },
            others: pagination.others,
        })


        let variables = {
            first: listSize || defaultPageSize, // Number(pagination.first),
            after: 0, // Number(pagination.after),
            filter: JSON.stringify({ ..._filters, ...permanentFilter }),
            others: JSON.stringify(pagination.others)
        }

        if (_filters.keyword){
            threadsTicketsQuerySearch({ variables }).then(r => {
                set_ticketList(r?.data?.threadsTicketsQuerySearch?.edges);
                set_queryResults(r?.data?.threadsTicketsQuerySearch);
            })
        }else{
            threadsTicketsQuery({ variables }).then(r => {
                set_ticketList(r?.data?.threadsTicketsQuery?.edges);
                set_queryResults(r?.data?.threadsTicketsQuery);
            })
        }
        */

        return false;
    };

    const handleEventChange = ({ detail }) => {
        if (detail.mutation == "TICKET_UPDATED" || detail.mutation == "TICKET_CLOSED" || detail.mutation == "TICKET_ARCHIVED") {
            if (queryResults && queryResults?.edges && detail.ticket._id) {
                let _threadsList = queryResults?.edges.map(o => ((o._id == detail.ticket._id) ? detail.ticket : o))

                set_ticketList(_threadsList)
            }
        }

    }

    const onSearch = async (values) => {
        let filters = { ...permanentFilter, ...values }
        await handleTableChange({ first: listSize || defaultPageSize, after: 0, filters })
        // await sleep(2000)
        return true;
    }

    return (<>
        <SubscriptionHandler handleEventChange={handleEventChange} />

        <Row className='nowrap'>
            <Col flex="auto">
                <ListHeader title={title || `Threads`} totalCount={(queryResults && queryResults?.totalCount) || 0} />
                {queryResults?.edges && queryResults?.edges[0] && queryResults?.edges[0]?.channel?.title || null}
            </Col>
            <Col><TicketsSearchBar onSearch={onSearch} /></Col>
        </Row>

        <Table loading={loading || search_loading} size="small"
            // title={() => <b>Total {((data && data.totalCount) || '0')} record(s) found </b>}
            columns={columns()}
            // dataSource={(data && data.threadsTicketsQuery && data.threadsTicketsQuery.edges) || null}
            dataSource={ticketList || null}
            pagination={{ 
                ...pagination, 
                pageSize: pagination.first,
                total: (queryResults && queryResults?.totalCount) || 0
            }}
            // onChange={(_filter, _pagination, _sort) => handleTableChange(_pagination, null)}
            onChange={({ after, current, filter, others, pageSize, total }) => {
                handleTableChange({ after, current, pageSize, total })
            }}
            // scroll={{ x: 1500, y: this.dimensions.height - 350 }}
            rowClassName={(rec => {
                let classes = 'show-menu-on-hover '
                // const isViewed = rec?.viewed_by.find(o => (o.user_id == this.props.user._id));
                // const expired = rec?.expiry_date ? mongoToDate(rec.expiry_date).isBefore(mongoToDate()) : false;
                // if (!isViewed) classes += ' yellow-table-row'

                return classes; //record.status == 'disabled' ? 'disabled-table-row' : "";
            })}
            // expandable={{
            //   expandedRowRender: record => <div style={{ margin: 0 }}>
            //     <p>{record.body}</p>
            //   </div>,
            //   // rowExpandable: record => record.comments && record.comments.length > 0,
            // }}
        />
    </>)
}
TicketList.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filter: PropTypes.object, 
    permanentFilter: PropTypes.object, 
    onEditClick: PropTypes.func, 
    onDeleteClick: PropTypes.func,
    listSize: PropTypes.number,
}


const mapStateToProps = ({ ep_admin }) => {
    return ({
        user: ep_admin.user,
        thr_tik_history_fltr: ep_admin?.pageSettings?.thr_tik_history_fltr || {},
    });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     on_updatePageSettings: (payload) => dispatch(updatePageSettings(payload)),
// })
export default connect(mapStateToProps, null)(TicketList)
