import React, { useState, useEffect } from 'react'
import { Button, DevBlock, Drawer, Table } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { defaultPageSize } from 'configs';
import { Alert, Col, Pagination, Row, Tag, Timeline } from 'antd';
import AssetTrackForm from './AssetTrackForm';
import { mongoToDate } from 'Common/scripts/Functions';

const LIST_DATA = loader('src/Graphql/trackable_assets/getAsstTracksQuery.graphql');
// const defaultPageSize = 5;


export default function TrackDetails({ assetData }) {
    const [pagination, setPagination] = useState({ current: 1, first: defaultPageSize, after: 0, filter: { }, others: { sort: { _id: -1 } } })
    const [queryResults, set_queryResults] = useState(null)
    const [busy, setBusy] = useState(false)
    const [error, setError] = useState(null)
    const [showForm, set_showForm] = useState(false)

    const [getAsstTracksQuery, { called, loading, data }] = useLazyQuery(
        LIST_DATA, { variables: { asset_id: assetData._id, ...pagination, filter: JSON.stringify(pagination.filter), others: JSON.stringify(pagination.others) } }
    );

    useEffect(() => {
        if (called || loading || data) return;
        fetchData({ 
            filter:{},
            others:{},
            pagination: {
                first: defaultPageSize,
                after: 0
            }
        })
    }, [])

    const fetchData = async (args) => {
        let filter = args.filter; // (args && args.filter) || pagination.filter || {};
        let others = args.others; // (args && args.others) || pagination.others || {};
        let first = args.pagination.first; //(args && args?.pagination?.first) || pagination.first || defaultPageSize;
        let after = args.pagination.after; // (args && (args?.pagination?.after || args?.pagination?.after == 0) && args?.pagination?.after) || pagination.after || 0;

        setPagination({ first, after, filter, others, })

        let variables = {
            asset_id: assetData._id,
            first,
            after,
            filter: JSON.stringify(filter),
            others: JSON.stringify(others)
        }

        setBusy(true);
        let results = await getAsstTracksQuery({ variables, fetchPolicy: 'network-only' }).then(r => (r?.data?.getAsstTracksQuery))
            .catch(err=>{
                console.log(__error("Error: "), err)
                return { error:{message:"Request Error"}}
            })
        setBusy(false)

        if (!results || results.error){
            setError((results && results?.error?.message) || "Invalid Response!")
            return;
        }

        set_queryResults(results)
        return results;
    }

    const handleTableChange = (_pagination, filters, sorter) => {
        fetchData({
            filter: pagination.filter,
            others: pagination.others,
            pagination: {
                first: defaultPageSize,
                after: _pagination.pageSize * (_pagination.page - 1)
            }
        })
    };

    const doSearch = (values) => {
        let _filter = {}
        if (values.kw) Object.assign(_filter, { search: { keywords: values.kw } })
        if (values.type) Object.assign(_filter, { type: values.type })

        fetchData({
            pagination: {
                first: defaultPageSize,
                after: 0,
            },
            filter: _filter
        })
    }


    if (showForm){
        return (<>
            <AssetTrackForm assetData={assetData} 
                onCancel={() => set_showForm(false)}
                onSuccess={()=>{
                    set_showForm(false);
                    fetchData({});
                }}
            />
        </>)
    }

    // console.log("queryResults: ", queryResults.asset_tracks_query.totalCount)

    return (<>
        <h3 style={{ margin: 0 }}>{assetData.title}</h3>
        <div>Site: {assetData.site.name}</div>

        {error && <Alert message={error} showIcon type='error' />}
        <div style={{ padding:"20px 0" }}><Button onClick={()=>set_showForm(true)} size="small" block>Add track</Button></div>

        {queryResults && queryResults?.asset_tracks_query?.edges && <>
            <Timeline>
                {queryResults?.asset_tracks_query?.edges?.map((rec, i) => {
                    return <Timeline.Item key={i}>
                        <Row gutter={[20, 0]}>
                            <Col><div style={{ fontWeight: "bold" }}>{rec.employee.name}</div></Col>
                            <Col>{mongoToDate(rec.created_at).format("DD-MM-YYYY HH:mm")}</Col>
                            <Col><Tag>{rec.status}</Tag></Col>
                            <Col><div style={{ fontSize: "12px", color: "#999" }}>by: {rec?.author?.name}</div></Col>
                        </Row>
                        <div>{rec.notes}</div>
                    </Timeline.Item>
                })}
            </Timeline>

            {queryResults?.asset_tracks_query?.totalCount > 0 && <>
                <hr />
                <Pagination 
                    showTotal={(total, range) => (<div>Total {total}</div>)}
                    defaultCurrent={pagination.current} 
                    pageSize={pagination.first}
                    total={queryResults.asset_tracks_query.totalCount} 
                    onChange={(page, pageSize) => handleTableChange({ page, pageSize })}
                />
            </>}

        </>}



        {/* <Table
            loading={busy}
            columns={columns}
            dataSource={queryResults ? queryResults?.asset_tracks_query?.edges : null}
            pagination={{ ...pagination, pageSize: defaultPageSize }}
            // onChange={handleTableChange}
            // title={() => <span>Header</span>}
        /> */}

        {/* <DevBlock obj={queryResults && queryResults.asset_tracks_query} title="asset_tracks_query" />
        <DevBlock obj={assetData} title="assetData" />
        <DevBlock obj={queryResults} title="queryResults" /> */}

    </>)
}
