import React from 'react';
import { Button as _Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import Icon from './Icon';
import _ from 'lodash'
import { useHistory, useParams, useLocation, Link } from "react-router-dom";

// https://ant.design/components/button/

/***
 * disabled={boolean}
 * ghost={boolean} // transparent background
 * href={string} // redirect url of link button
 * target={string} // same as target attribute of a, works when href is specified
 * htmlType={string}
 * icon={ReactNode}
 * loading={boolean}
 * shape={circle | round}
 * size={large | middle | small}
 * type={primary | ghost | dashed | link}
 * block={boolean}
 * danger={boolean}
 */

// export const Button = props => <_Button {...props} type={props.type || "primary"} />;
/*****
 * React.forwardRef used to fix refs error
 * ERROR: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()
 */
export const Button = React.forwardRef((props, ref) => {
    const _props = {...props, style:{...props.style}};
    // add left margin if icon is provided with children
    if (props.icon && props.children){
        Object.assign(_props, { children: <span style={{marginLeft:'5px'}}>{props.children}</span>})
    }
    if(props.color){
        delete _props.color;
        Object.assign(_props, { className: `btn_${props.color} ${props.className || ""}` })
        // Object.assign(_props, {style: { ...props.style, background: props.color }})
    }

    return (<_Button ref={ref} {..._props} type={props.type || "primary"} />)
} );
export default Button;

export const ToggleButton = props => {
    const propTypes = {
        labelA: PropTypes.any.isRequired,
        labelB: PropTypes.any,
        toggle: PropTypes.bool,
        // icon: PropTypes.oneOfType([
        //             PropTypes.string,
        //             PropTypes.object
        //         ]),
    }

    let _props = { ...props };
    _props = Object.assign(_props)
    delete _props.toggle;
    delete _props.labelA;
    delete _props.labelB;
    // <Button type="primary" shape="circle" icon={<SearchOutlined />} />
    return <Button {..._props} >{!props.toggle ? props.labelA : (props.labelB || props.labelA)}</Button>

}


// export const IconButton = props => {
export const IconButton = React.forwardRef((props, ref) => {
    let _props = { ...props };
    delete _props.icon;

    return (<Button ref={ref} shape='circle' style={{ margin: '3px' }} size={props.size || "small"} type={props.type || "default"} {..._props}>
        {_.isString(props.icon) ? <Icon style={{ fontSize: "0.8em" }} icon={props.icon} /> : <Icon style={{ fontSize: "0.8em" }} {...props.icon} />}
    </Button>)
});

export const BackButton = ({ onClick, href, style }) => {
    let history = useHistory();
    if (onClick) return <Button type="link" onClick={() => onClick ? onClick() : history.goBack()}><Icon icon="chevron-left" style={{ marginRight: "10px", ...style }} /> Back</Button>
    if (href) return <Button type="link"><Link to={`${href}`}><Icon icon="chevron-left" style={{ marginRight: "10px", ...style }} /> Back</Link></Button>

    return <Button type="link" onClick={() => history.goBack()}><Icon icon="chevron-left" style={{ marginRight: "10px", ...style }} /> Back</Button>
}


export const DeleteButton = ({ onClick, message, children, placement="top" }) => {
    return (<Popconfirm onConfirm={onClick} placement={placement} title={message || "Are you sure to delete this record？"}>
        {children || <span><IconButton type="danger" icon="trash-alt" /></span>}
    </Popconfirm>)
}