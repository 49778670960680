import React from 'react'
import TicketList from 'Modules/Threads/components/tickets/TicketList'

export function ThreadsHilights() {
  return (<>
    <TicketList
        listSize={5}
        filter={{ status: "open" }}
        onDeleteClick={null}
        onEditClick={null}
        permanentFilter={{ "channel._id": Number(1000) }}
    />

  </>
  )
}
