import React, { useState } from 'react'
import { Button, ValuePairsSearch, Icon, IconButton } from 'Common/components';
import { Col, Row, Modal, Space, Upload, message } from 'antd';
import { rules, FormField, FormComponent } from 'Common/components/Form'
import moment from 'moment'
import axios from 'axios'
import { __error } from 'Common/scripts/consoleHelper'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { loader } from 'graphql.macro';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
// import { dateToMongo } from 'Common/scripts/Functions';
import { defaultDateFormat } from 'configs';

const FILE_LIMIT = 2;
const REMOVE_ASSET = loader('src/Graphql/guard/removeGuardAsset.graphql');
const UPDATE_ASSET = loader('src/Graphql/guard/updateGuardAssets.graphql');
const ADD_NOTE = loader('src/Graphql/notes/addNotes.graphql');
const DELETE_NOTE = loader('src/Graphql/notes/deleteNotes.graphql');

const AssetItem = ({ data, item, onUpdate, client }) => {
    const [showForm, set_showForm] = useState(false);
    const [busy, set_busy] = useState(false);

    const handleUpload = async (fileList, _id) => {
        if (!fileList || fileList.length < 1) return; // { error: { message: "No file to upload!!" } }
        if (fileList.length > FILE_LIMIT) return { error: { message: `You can upload max of ${FILE_LIMIT} files only!` } }
        if (!_id) return { error: { message: `Missing upload ID` } }

        const hide = message.loading('Uploading..', 0);

        const formData = new FormData();
        formData.append('action', "notes.attachments");
        formData.append('note_id', _id);
        fileList.forEach(file => {
            formData.append('files[]', file);
        });

        const results = await axios.post(process.env.REACT_APP_API_URL + '/note_upload/upload', formData)
            .then(({ data }) => {
                return data;
            })
            .catch(function (error) {
                console.log(__error("Upload error: "), error)
                return { error: { message: "Upload request fail!" } };
            });

        hide();
        return results;
    };

    const saveNote = async (values) => {
        const note_input = {
            rec_id: data._id,
            note: `${item} has been returned, 
                recevied by: ${values.received_by} 
                in condition: (${values.condition}) 
                on: ${moment(values.receiving_date).format(defaultDateFormat)}`,
            // user_id: this.props.ep_admin.user._id,
            // user_email: this.props.ep_admin.user.email,
            ref_key: "Guard_Information"
        }
        const results = await client.mutate({ mutation: ADD_NOTE, variables: { input: note_input } }).then(r => {
            return r.data.addNotes;
        }).catch(err => {
            console.log(__error("ERROR: "), err)
            // this.setState({ busy: false, error: "Unable to submit your request at the moment!" });
            return { error: { message: "Unable to submit your request at the moment!" } };
        })
        if (results.error) return results;

        if (values.fileList && values.fileList.length>0){
            var upload_results = await handleUpload(values.fileList, results._id).then(r => (r))
                .catch(err => {
                    console.log(__error("ERROR: ", err));
                    return { error: { message: 'Unable to complete upload request' } }
                });
            if (upload_results && upload_results.error) {
                // remove new note
                message.error(upload_results.error.message);
    
                return await client.mutate({ mutation: DELETE_NOTE, variables: { id: results._id } }).then(r => {
                    return r.data.deleteNotes;
                }).catch(err => {
                    console.log(__error("ERROR: "), err)
                    return { error: { message: "Unable to submit your request at the moment!" } };
                })
                return upload_results;
            }

        }
        // const finalResults = (upload_results && upload_results.data) ? upload_results.data : results;

        return results;
    }

    const onSubmit = async(values) => {
        set_busy(true);

        // add note
        let results = await saveNote(values);
        if (results.error) {
            set_busy(false);
            message.error(results.error.message);
            return false;
        }


        // remove selected asset
        results = await client.mutate({ mutation: REMOVE_ASSET, variables: { id:data._id, asset:item } }).then(r => {
            return r.data.removeGuardAsset;
        }).catch(err => {
            console.log(__error("ERROR: "), err)
            return { error: { message: "Unable to submit your request at the moment!" } };
        })
        set_busy(true);
        if (results.error) return results;

        set_showForm(false)
        onUpdate(results);
        return results;
    }

    return (<>
        <span style={{ display: "inline-flex", alignItems: "center", flexDirection: "row", whiteSpace: "nowrap", flexWrap: "nowrap", textTransform: "capitalize", backgroundColor: "#EEE", padding: "0px 0 0px 10px", borderRadius: "5px", fontSize: "16px" }}>
            <span>{item}</span>
            <span><IconButton onClick={() => set_showForm(true)} type="link" icon="times" /></span>
        </span>

        <Modal title={`Return Asset ( ${item} )`} visible={showForm} footer={false} onCancel={() => set_showForm(false)} destroyOnClose>
            {showForm && <>
                <FormComponent onSubmit={onSubmit} id='GuardStatusUpdate' fields={{}} debug={false}
                    mutators={{
                        ...arrayMutators,
                        selectFiles: (newValueArray, state, tools) => {
                            let file = newValueArray[0];
                            let action = newValueArray[1];
                            let fileList = state.formState.values.fileList || []
                            if (fileList.length >= FILE_LIMIT) return;

                            if (action == 'add') fileList.push(file);
                            if (action == 'remove') fileList = fileList.filter(o => o.uid != file.uid);

                            // tools.changeValue(state, 'file_count', () => fileList.length);
                            tools.changeValue(state, 'fileList', () => fileList);
                            // // tools.changeValue(state, 'tagged_to_users', () => _client);
                        },
                        removeFiles: (newValueArray, state, tools) => {
                            tools.changeValue(state, 'fileList', () => []);
                            // // tools.changeValue(state, 'tagged_to_users', () => _client);
                        },
                    }}

                    form_render={formProps => {
                        const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                        return (<>
                            <p style={{fontSize:"18px"}}>Return of {item}</p>

                            <Space direction="vertical" style={{ width: "100%" }}>
                                <FormField size="medium" name="condition" validate={rules.required} type="textarea" label="Asset condition" placeholder="Provide details of condition asset is in" compact />
                                <FormField size="medium" name="received_by" validate={rules.required} type="text" label={"Asset received by"} compact />
                                <FormField size="medium" name="receiving_date" validate={rules.required} type="date" label={"Date of receival"} compact />
                            </Space>

                            <Upload
                                onRemove={(file) => {
                                    form.mutators.selectFiles(file, "remove");
                                }}
                                beforeUpload={(file) => {
                                    form.mutators.selectFiles(file, "add");
                                    return false;
                                }}
                                // fileList={this.state.fileList}
                                fileList={values.fileList}
                                accept=".jpg,.png,.pdf"
                                multiple={true}
                                maxCount={6}
                            >

                                <Button size='small' type="default" style={{ marginLeft: "0px" }} disabled={values?.fileList?.length >= 6 ? true : false}><Icon icon="paperclip" style={{ marginRight: '10px' }} /> Select files to attach</Button>
                            </Upload>
                            <FieldArray name="fileList">{({ fields }) => null}</FieldArray>


                            <div style={{ height: "20px" }} />
                            <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >Proceed</Button>

                        </>)
                    }}
                />

            </>}
        </Modal>
    </>)
}

const AddAssetForm = ({ data, onUpdate, showForm, onClose, client }) => {
    
    const onSubmit = async (values) => {
        if (!values.company_assets || values.company_assets.length<1) return false;

        let company_assets = data?.company_assets?.slice() || []
        company_assets = company_assets.concat(values.company_assets);

        const results = await client.mutate({ mutation: UPDATE_ASSET, variables: { id: data._id, assets: company_assets.toString() } }).then(r => {
            return r.data.updateGuardAssets;
        }).catch(err => {
            console.log(__error("ERROR: "), err)
            return { error: { message: "Unable to submit your request at the moment!" } };
        })
        if (results.error){
            message.error(results.error.message);
            return false;
        }

        onUpdate(results)
        onClose();
        return false;
    }

    return(<>
        <Modal title={`Add assets`} visible={showForm} footer={false} onCancel={() => onClose(false)} destroyOnClose>
            {showForm && <>
                <FormComponent onSubmit={onSubmit} id='GuardStatusUpdate' fields={{}} debug={false}
                    form_render={formProps => {
                        const { handleSubmit, submitting, values, form, invalid, dirty, valid } = formProps;

                        return (<>
                            <ValuePairsSearch
                                keyMap="values=>title"
                                multiselect={false}
                                filter={data?.company_assets?.length > 0 ? { values: { $nin: data.company_assets }} : {}}
                                preload key_value="company_assets"
                                name="company_assets"
                                label="Please select the assets that are provided to guard by compnay"
                                style={{ width: "100%" }}
                            />

                            <div style={{ height: "20px" }} />
                            <Button type="primary" size="medium" block htmlType='submit' disabled={!dirty || !valid} loading={submitting} >Add</Button>

                        </>)
                    }}
                />

            </>}
        </Modal>

    </>)
}

const CompnayAssets = ({ data, onUpdate, client }) => {
    const [showForm, set_showForm] = useState(false);

    return (<>
        <div style={{ fontWeight: "bold", fontSize: "20px", color: '#060079' }}><Icon icon="cubes" /> Compnay Assets <IconButton onClick={() => set_showForm(true)} icon={"plus"} /></div>
        {/* <DataRow style={{ textTransform: "capitalize" }} col1={""} col2={`${data.company_assets && data.company_assets.toString().replace(",", ", ")}`} /> */}

        <AddAssetForm showForm={showForm} data={data} onUpdate={onUpdate} onClose={() => set_showForm(false)} client={client} />

        <Row gutter={[10, 10]}>
            {data?.company_assets?.map((item, i)=>{
                return (<Col key={i}><AssetItem data={data} client={client} item={item} onUpdate={onUpdate} /></Col>)
            })}
        </Row>
    </>)
}
export default withApollo(CompnayAssets);