import React, { Component, useEffect, useState, useMemo } from 'react'
import { Avatar, Button, UserCardPopover, Loader, BackButton, ShowAttachments, IconButton } from 'Common/components'
import { ContentArea } from 'Layout_v1'
import { Col, Row, Space, Alert, message, Divider, Modal, Tag } from 'antd'
import { mongoToDate, scrollTo } from 'Common/scripts/Functions';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { defaultDateTimeFormat, uriRoot } from 'configs';
import { connect } from "react-redux";
import { ThreadMessage, ThreadHeader, PostCommentsForm, TicketClosingForm } from '../../components/tickets'
import TicketFormModal from 'Modules/Threads/components/tickets/ticket_form';
import TicketMembersForm from 'Modules/Threads/components/tickets/TicketMembersForm';

const GET_TICKET = loader('src/Graphql/threads/threadsTicket.graphql');


class SubscriptionHandler extends Component {
  componentDidMount(){
    document.body.addEventListener('message-event', this.onHandel.bind(this));
  }
  
  componentWillUnmount(){
    document.body.removeEventListener('message-event', this.onHandel.bind(this));
  }

  onHandel(e){
    this.props.handleEventChange(e)
  }

  render() {
    return null
  }
}




function TicketDisplay({ match, user }) { // match.params.id
  const [messagesArray, set_setMessagesArray] = useState([])
  const [showAllDiscardedMessages, set_showAllDiscardedMessages] = useState(false)
  const [showClosing, set_showClosing] = useState(false)
  const [showEditForm, set_showEditForm] = useState(false)
  const [showMembersForm, set_showMembersForm] = useState(false)
  const [replyTo, set_replyTo] = useState(null)

  const [loadTicket, { called, loading, data }] = useLazyQuery(
    GET_TICKET, { variables: { filter: JSON.stringify({ _id: Number(match.params.id) }) } }
  );

  const handleEventChange = ({ detail }) => {

    if (detail.mutation == "MESSAGE_DISCARDED" || detail.mutation=="MESSAGE_UPDATED") {
      if (data.threadsTicket._id == detail.message.ticket_id){
        let _messagesArray = messagesArray.map(o => (o._id == detail.message._id) ? detail.message : o);
        set_setMessagesArray(_messagesArray)
      }
    }

    if (detail.mutation == "MESSAGE_ADDED") {
      if (data.threadsTicket._id == detail.message.ticket_id){
        let _messagesArray = messagesArray.slice();
            _messagesArray.push(detail.message);
        set_setMessagesArray(_messagesArray)
      }
    }

    if (detail.mutation == "MESSAGE_DELETED") {
      if (data.threadsTicket._id == detail.message.ticket_id){
        let _messagesArray = messagesArray.slice();
            _messagesArray = _messagesArray.filter(o => !(o._id == detail.message._id))
        set_setMessagesArray(_messagesArray)
      }
    }

  }

  useEffect(() => {
    loadTicket().then(r=>{
      if (r?.data?.threadsTicket?.error){
        message.error(r.data.threadsTicket.error.message);
        return r;
      }
      else{
        set_setMessagesArray(r?.data?.threadsTicket?.messages);
      }
      return r;
    })
  }, [match.params.id])

  const onSuccess = () => {
    set_showClosing(false);
    loadTicket();
  }

  const onReplyTo = ({ image, message }) => {
    set_replyTo({ image, message })
    scrollTo("ThreadMessageForm")
  }

  const onEditClick = () => {
    set_showEditForm(true)
  }
  const onDeleteClick = () => {
    alert("Deletion not allowed")
  }

  if (loading) return <Loader loading={loading} center />
  if (!data || !data?.threadsTicket || data?.threadsTicket?.error) return <Alert message={(data && data?.threadsTicket?.error?.message) || "No records found!"} type='error' showIcon />


  return (<>
    <SubscriptionHandler handleEventChange={handleEventChange} messagesArray={messagesArray} ticket={data.threadsTicket} />

    <Space>
      <BackButton />
      <div> | </div>
      <a href={`${uriRoot}/threads/channel/${Number(data.threadsTicket.channel._id)}`}>{data.threadsTicket.channel.title}</a>
    </Space>


    <Row className='nowrap'>
      <Col flex="auto">
        <ThreadHeader onEditClick={onEditClick} onDeleteClick={onDeleteClick} data={data.threadsTicket} showAllDiscardedMessages={showAllDiscardedMessages} set_showAllDiscardedMessages={set_showAllDiscardedMessages} />

        <div style={{ margin: "10px" }}><b>
          {data?.threadsTicket?.messages?.length > 1 ? `${data?.threadsTicket?.messages?.filter(o => o.status != "archived")?.length || 0} comment(s)` : "No one has responded yet!"} 
        </b></div>

        {/* <Space direction='vertical' style={{ width: "100%", height:"calc(100vh - 400px)", overflowY:"auto" }}> */}
        <Space direction='vertical' style={{ width: "100%" }} size={5}>
          {messagesArray?.filter(o => {
            if (showAllDiscardedMessages) return true;
            return (o.status != "archived")
          }).map((item, i) => {
            return <ThreadMessage onReplyTo={onReplyTo} ticket_status={data.threadsTicket.status} messageData={item} ticket_id={data?.threadsTicket?._id} key={i} />
          })}
        </Space>

        {data.threadsTicket.status == "open" && <PostCommentsForm replyTo={replyTo} onCancelReply={() => set_replyTo(null)} ticket={data.threadsTicket} user={user} />}

      </Col>
      
      <Col flex="300px">
        {data.threadsTicket.status == "open" && <Button style={{ margin: "0 0 10px 10px" }} block color="green" onClick={() => set_showClosing(!showClosing)}>Close Ticket</Button>}
        
        <ContentArea style={{ margin:"0 0 10px 10px"}}>
          <div>
            <span className='gray3'>Creatred:</span> {mongoToDate(data.threadsTicket.created_at).format(defaultDateTimeFormat)}<br />
            {/* <p><span className='gray3'>Last updated by</span> {data.threadsTicket.updated_by.name} at {mongoToDate(data.threadsTicket.updated_at).format(defaultDateTimeFormat)}</p> */}
            {data.threadsTicket.closed_by && <div>
              <span className='gray3'>Closed at</span> {mongoToDate(data.threadsTicket.closed_at).format(defaultDateTimeFormat)} by {data.threadsTicket.closed_by.name}
            </div>}
          </div>
          {data?.threadsTicket?.messages?.length > 0 && <div>
            <div className='gray3'>Last response by:</div>
            <b>{data.threadsTicket.messages[data.threadsTicket.messages.length - 1].author.name}</b> at {mongoToDate(data.threadsTicket.messages[data.threadsTicket.messages.length - 1].created_at).format(defaultDateTimeFormat)}
          </div>}
          {/* <p>total Time</p> */}
          <div>
            <Divider><b>Members</b> <IconButton onClick={() => set_showMembersForm(!showMembersForm)} icon="pen" /></Divider>
            <Space direction='vertical'>
              {data.threadsTicket.members.map((o, i)=>{
                {/* return <Tag color='blue' key={i}>{o.name}</Tag> */}
                return <div style={{ fontSize: "14px" }} key={i}><UserCardPopover userInfo={o}><Avatar src={o.avatar} alt={o.name} size={32} /></UserCardPopover> {o.name}</div>
              })}
            </Space>
          </div>

          {data?.threadsTicket?.attachments?.length > 0 && <div>
            <Divider><b>Attachments</b></Divider>
            <ShowAttachments attachments={data?.threadsTicket?.attachments} />
            {/* <Space direction='vertical'>
              {data.threadsTicket.members.map((o, i)=>{
                return <div style={{ fontSize: "14px" }} key={i}><UserCardPopover userInfo={o}><Avatar src={o.avatar} alt={o.name} size={32} /></UserCardPopover> {o.name}</div>
              })}
            </Space> */}
          </div>}

        </ContentArea>
      </Col>
    </Row>

    <Modal title="Confirm Closing Ticket" visible={showClosing} footer={false} onCancel={() => set_showClosing(false)}>
      {showClosing  && <>
        <TicketClosingForm ticket={data.threadsTicket} onSuccess={onSuccess} />
      </>}
    </Modal>

    <TicketMembersForm visible={showMembersForm} onCancel={() => set_showMembersForm(false)} onSuccess={() => set_showMembersForm(false)} initialValues={data?.threadsTicket} />
    <TicketFormModal visible={showEditForm} onCancel={() => set_showEditForm(false)} onSuccess={() => set_showEditForm(false)} initialValues={data?.threadsTicket} />
  </>)

}
const mapStateToProps = ({ ep_admin:{user} }) => {
  return ({ user });
}
export default connect(mapStateToProps, null)(TicketDisplay)

