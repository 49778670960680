/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Breadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons';
import { ContentArea } from "Layout_v1";
import { connect } from "react-redux";
import { uriRoot } from 'configs';
import { withApollo } from '@apollo/client/react/hoc';
import { Tabs } from 'Common/components';
import { testSaga, testSagaSync } from 'Store/saga.actions';
// import NoticeBoardView_Hilights from 'Modules/Noticeboard_note/containers/NoticeBoardView_Hilights';
import ScheduleHours_Hiligts from 'Modules/HoursSchedules/containers/ScheduleHours_Hiligts';
import { ApplicationsHilights } from 'Modules/FormsData/Home';
import TimelineRecords from 'Modules/Timeline';
import { DorsHighlights, ThreadsHilights } from './components';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import { __error } from 'Common/scripts/consoleHelper';

const CHANGE_STATUS = loader('src/Graphql/addNotificationQueue/processNotificationQueue.graphql');

/**
 * Dashboard
 */
const DashboardComp = props =>  {
  const [activeTab, set_activeTab] = useState('GuardApplications');

  const [processNotificationQueue, process_details] = useMutation(CHANGE_STATUS);

  useEffect(() => {
    if (process_details.called) return;
    processNotificationQueue().then(r=>{
    }).catch(err=>{
      console.log(__error("Error: ", err))
    });
  }, [])
   
  return (<>
    <Breadcrumb style={{ padding: "10px" }}>
      <Breadcrumb.Item href={uriRoot}><HomeOutlined /></Breadcrumb.Item>
      {/* <Breadcrumb.Item href="/templates"><span>Templates</span></Breadcrumb.Item> */}
      <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
    </Breadcrumb>

    <div style={{height:"20px"}} />

    <div style={{ padding:"10px" }}>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <div style={{ margin: "0 10px" }}>
            <Tabs size="small"
              tabsArray={[
                { title: "Guard Applications", key: "GuardApplications" },
                { title: "Client Info Queries", key: "ClientInfoQueries" },
                { title: "Guard Interviews", key: "GuardInterviews" },
              ]}
              onChange={set_activeTab}
            />
          </div>
          <ContentArea style={{ margin: "0", height: "400px", overflow: "auto" }}>
            {activeTab == 'GuardApplications' && <ApplicationsHilights ref_key="guard_application_form" />}
            {activeTab == 'ClientInfoQueries' && <ApplicationsHilights ref_key="client_info_form" />}
            {activeTab == 'GuardInterviews' && <ApplicationsHilights ref_key="security_guard_interview" />}
          </ContentArea>
        </Col>
        <Col span={12}>
          {/* <TimelineRecords /> */}
          <DorsHighlights />
        </Col>
        <Col span={12}><ThreadsHilights /></Col>
      </Row>
    </div>


    {/* <ContentArea style={{ margin: "0", height: "426px" }}>
      <NoticeBoardView_Hilights filter={{ status: { $ne: "resolved" } }} tableProps={{ scroll: { y: 258, x: 300 } }} />
    </ContentArea> */}

    <ScheduleHours_Hiligts style={{   }} />

    {/* <div style={{ borderRadius:"5px", overflow:"hidden", border:"1px solid black" }}><ScheduleHours_Hiligts /></div> */}
    {/* <ContentArea style={{  }}>
    </ContentArea> */}



      {/* <FormDataHome inline /> */}
      {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <span style={{ fontWeight: "bold", fontSize: "22px", color: "purple", marginBottom: "15px" }}>Employement Applications</span>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Progress type="circle" percent={data} format={percent => `${percent}`} />
            <span style={{ marginTop: 4, color: "purple" }}>New Apllications</span>
          </div>

          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Progress type="circle" percent={(total / total) * 100} format={percent => `${total}`} />
            <span style={{ marginTop: 4, color: "purple" }}>Total Apllications</span>
          </div>
        </div>
      </div> */}

      {/* <Button onClick={() => this.props.testSaga()}>testSaga</Button>
              <Button onClick={() => this.props.testSagaSync()}>testSagaSync</Button> */}

  </>)

}
DashboardComp.propTypes = {
  // login: PropTypes.object.isRequired,
}

const Dashboard = withApollo(DashboardComp);

const mapStateToProps = ({ ep_admin }) => {
  return ({ ep_admin });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  // doLogin: (payload) => dispatch(doLogin(payload)),
  testSaga: (payload) => dispatch(testSaga(payload)),
  testSagaSync: (payload) => dispatch(testSagaSync(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
